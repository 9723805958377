<template>
    <div style="padding-top: 8em;">
        <section class="intro clearfix header_service">
            <div class="container">
                <div class="row">
                    <div class="overlay-green"></div>
                            <div class="col-md-12 intro-info text-left" style="z-index: 1;">
                                <h1 class="text-uppercase bold text-white mb-4 text-shadow ls-1">CEK KBLI ANDA DISINI!</h1>
                                <p class="h5 mb-5 text-white text-shadow">
                                    Panduan Lengkap KLASIFIKASI BAKU LAPANGAN USAHA INDONESIA (KBLI) 2020
                                </p>
                            <div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-100">
            <div class="container">
                <div class="top-over">
                    <v-row align="center" justify="center">
                        <div class="col-sm-6">
                            <h2 class="text-center">
                                Klasifikasi Usaha (KBLI)
                            </h2>
                        </div>
                    </v-row>
                    <v-row>
                        <div class="col-sm-12 mb-0 pb-0">
                            <div class="card border-0">
                                <div class="card-body rounded" style="background:#f5f6f8">
                                    <div class="row align-items-center">
                                        <div class="col-sm-3">
                                            <img src="@/assets/whats.png" class="img-fluid">
                                        </div>
                                        <div class="col-sm-9">
                                            <p class="h2">
                                                Apa Itu KBLI ?
                                            </p>
                                            <p class="small mb-0 text-secondary">
                                                KBLI (Klasifikasi Baku Lapangan Usaha Indonesia) adalah kode klasifikasi resmi untuk mengklasifikasikan jenis bidang usaha perusahaan di Indonesia. Perusahaan yang ingin mendaftarkan bidang usahanya di Akta ataupun di NIB harus memasukkan kode yang sesuai dengan klasifikasi di KBLI. KBLI disusun BPS (Badan Pusat Statistik) dengan merujuk pada International Standard Classification of All Economic Activities (ISIC), ASEAN Common Industrial Classification (ACIC), dan East Asia Manufacturing Statistics (EAMS).
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 mt-0 pt-0 mb-0 pb-0">
                            <div class="card border-0">
                                <div class="card-body rounded" style="background:#f5f6f8">
                                    <div class="row align-items-center">
                                        <div class="col-sm-9">
                                            <p class="h2">
                                                Apa Fungsi KBLI ?
                                            </p>
                                            <p class="small mb-0 text-secondary">
                                                KBLI berfungsi untuk menyeragamkan aktivitas ataupun kegiatan usaha di Indonesia menjadi sebuah klasifikasi yang bisa digolongkan. Standarisasi atau penyeragaman ini menjadi acuan untuk pendaftaran legalitas seperti di Akta Perusahaan ataupun NIB (Nomor Induk Berusaha).
                                            </p>
                                        </div>
                                        <div class="col-sm-3">
                                            <img src="@/assets/team.png" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 mt-0 pt-0 mb-0 pb-0">
                            <div class="card border-0">
                                <div class="card-body rounded" style="background:#f5f6f8">
                                    <v-data-table
                                        dense
                                        :headers="headers"
                                        :items="itemLists"
                                        class="elevation-1 mb-5"
                                        :items-per-page="30"
                                        :footer-props="{
                                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                        }"
                                        :loading="$store.state.overlay"
                                        height="440"
                                        fixed-header
                                        :divider="true"
                                        :light="true"
                                        :search="searchItem" 
                                        :item-class="tr_datatable"
                                    >    
                                        <template v-slot:top>
                                            <v-toolbar flat color="white" class="mt-2 mb-2">
                                                <div class="d-flex w-100">
                                                    <v-text-field solo style="max-width: 300px;" class="border-12 mr-3 " v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                    small
                                                    color="#fff"
                                                    class="py-5 mr-3 hidden-sm-and-down"
                                                    @click="getKbli()"
                                                    >
                                                        <v-icon>mdi-cached</v-icon>
                                                    </v-btn>
                                                    <!-- <v-btn
                                                    small
                                                    color="#005c37"
                                                    class="py-5 mr-3 text-white hidden-sm-and-down"
                                                    >
                                                        <v-icon>mdi-file-excel</v-icon>
                                                        <download-excel
                                                            class="text"
                                                            :fetch           = "exportExcel"
                                                            :fields="headersColumn"
                                                            :before-generate = "startDownload"
                                                            :before-finish   = "finishDownload">
                                                            Export Excel
                                                        </download-excel>
                                                    </v-btn> -->
                                                </div>
                                            </v-toolbar>
                                        </template>
                                    </v-data-table>
                                </div>
                            </div>
                        </div>
                    </v-row>
                </div>
            </div>
        </section>
        <!-- <contact-us></contact-us> -->
    </div>
</template>
<script>
import { mapState } from 'vuex'
import {kbli} from "@/backend-api/kbli"

import ContactUs from "@/components/layout_front/ContactUs.vue"

export default {
    components: {
        ContactUs
    },
    data: () => ({
        data : '',
        panel:[0,1,2,3],
        breadcumbs: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Konsultasi Hukum',
                disabled: true,
            }
        ],
        siteKey: '',
        headers:[
            { text: 'Kode', value: 'Kode' , align:'left', width:'100'},
            { text: 'Judul', value: 'Judul' , align:'left', width:'200'},
            { text: 'Katerangan', value: 'Keterangan' , align:'left'},
        ],
        itemLists: [],
        searchItem: '',
        headersColumn: {
            'Kode': 'code',
            'Judul': 'title',
            'Katerangan': 'description'
        },
    }),
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        async recaptcha() {
            // (optional) Wait until recaptcha has been loaded.
            // await this.$recaptchaLoaded()

            // Execute reCAPTCHA with action "login".
            // const token = await this.$recaptcha('login')

            // Do stuff with the received token.
        },
        handleSuccess(){

        },
        handleError(){

        },
        async getKbli(){
            this.$store.dispatch('setOverlay', true)
            var respData = await kbli.index(``, null, false, false, false)
            if (respData.status === 200) {
                this.itemLists = respData.data.data
                this.$store.dispatch('setOverlay', false)
            } 
        },
        async exportExcel(){

        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    },
    async mounted() { 
        this.$store.dispatch('setOverlay', true)
        await this.getKbli()
        this.$store.dispatch('setOverlay', false)
    },
    watch: {
    }
}
</script>

<style scoped>

</style>